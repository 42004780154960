<template>
    <div class="rule">
        <el-tabs v-model="activeName" @tab-click="handleClick" class="el-nav">
            <el-tab-pane label="我的余额" name="balance"></el-tab-pane>
            <el-tab-pane label="充值金币" name="pay"></el-tab-pane>

            <div class="title">
                <div class="left">充值金额</div>
                <div class="list">
                    <div class="num" :class="{ active: choseIndex === index }" v-for="(item, index) in payConfigs"
                         :key="index" @click="choseType(index, item)"> {{ item.balance }} 金币
                    </div>
                    <el-input :class="{numberSelect:numberCheck}" type="number" max="100000" min="1"
                              @focus="changeInput" maxlength="6" v-model.number.trim="input"
                              @blur="coins"
                              @input.native="amountInput($event,input)"
                              placeholder="其他金币"></el-input>
                </div>
            </div>
        </el-tabs>
        <div class="footer">
            <div class="msg">支付方式</div>
            <div class="state">
                <div class="pay_state" :class="{ active_pay: choseIndexPay === index }"
                     v-for="(item, index) in payState" :key="index" @click="choseState(index, item)">
                    <img :src="item.img" alt=""/>
                </div>
            </div>
            <div class="line"></div>
            <div class="total_price">
                <span class="amount_payable">应付金额:</span>
                <span class="payable">￥{{ payable }}.00</span>
            </div>
            <el-button type="primary" class="search-btn par" @click="openDiog">去充值</el-button>
        </div>
        <!--支付弹窗-->
        <el-dialog
                class="weiDiolog"
                :visible.sync="confirmDialogVisible"
                top="calc( 50vh - 150px )"
                icon="el-icon-warning"
                :destroy-on-close="true"
                :show-close="false"
                :close-on-click-modal="false"
                width="320px"
                center

        >
            <div class="info">
                <div class="title">{{ type === 1 ?'支付宝' : '微信' }}支付¥{{ this.payable }}.00</div>
                <div class="img_wei" v-loading="qr_code_loading">
                    <vue-qr v-if="qr_code" :margin="0" :text="qr_code" :size="190"></vue-qr>
                </div>
                <p class="await_wei">使用{{ type === 1 ?'支付宝' : '微信' }}扫描二维码进行支付</p>
                <div class="tips">
                    <img src="~assets/images/public/refresh.png" alt=""/>
                    <span @click="closePop">重新选择支付方式</span>
                </div>
            </div>
        </el-dialog>
        <!--成功-->
        <el-dialog
                class="sucDiolog"
                :visible.sync="sucdialogVisible"
                top="calc( 50vh - 150px )"
                icon="el-icon-warning"
                :destroy-on-close="true"
                :close-on-click-modal="false"
                :before-close="closePop"
                width="320px"
                center
        >
            <div class="info">
                <div class="img">
                    <img src="~assets/images/public/success.png" alt=""/>
                </div>
                <p class="await">支付成功</p>
                <p class="msg">付款成功 ¥{{ this.payable }}.00 当前金币 {{ totalAmount }}</p>
                <div class="tips">
                    <span class="look">查看</span>
                    <span class="to_balance" @click="toBalance">余额明细</span>
                </div>
            </div>
        </el-dialog>
        <!--失败-->
        <el-dialog
                class="errDiolog"
                :visible.sync="errdialogVisible"
                top="calc( 50vh - 150px )"
                icon="el-icon-warning"
                :destroy-on-close="true"
                :close-on-click-modal="false"
                width="320px"
                center
        >
            <div class="info">
                <div class="img">
                    <img src="~assets/images/public/err.png" alt=""/>
                </div>
                <p class="await">
                    点击
                    <span @click="recharge">去充值</span>
                </p>
                <p class="msg">继续完成支付</p>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import VueQr from 'vue-qr';

    export default {
        data() {
            return {
                confirmDialogVisible: false,
                sucdialogVisible: false,
                errdialogVisible: false,
                activeName: 'pay',
                input: '',
                choseIndex: 0,
                choseIndexPay: 0,
                recharge_id: null,
                payable: 0,
                type: 1,
                payConfigs: [],
                payState: [
                    {id: 1, img: require('../../../../assets/images/public/zhi.png')},
                    {id: 2, img: require('../../../../assets/images/public/wei.png')},
                ],
                qr_code: null,
                qr_code_loading: false,
                payment_status: false,
                order_no: null,
                gold: 0,
                totalAmount: 0,
                numberCheck: false
            };
        },
        created() {
            this.gold = this.$store?.getters?.userInfo?.managed_circle?.account?.gold;
            this.totalAmount = this.gold ? this.gold : 0;
            if (!this.gold) {
                this.initInfo();
            }

            this.handelConfig()
        },
        mounted() {

        },
        methods: {
            amountInput(e) {
                if (e.data) {
                    if (isNaN(parseInt(e.data))) {
                        e.target.value = "";
                        this.input = "";
                    } else {
                        if (parseInt(e.target.value) > 100000 ) {
                            this.input = 100000;
                        } else {
                            this.input = e.target.value;
                        }
                    }
                }
            },
            changeInput() {
                this.choseIndex = -1
            },
            initInfo() {
                this.$http.get(this.$api.userInfo, true).then(result => {
                    if (result.data.success) {
                        this.$store.dispatch('A_USER_INFO', result.data.data)
                        this.gold = result.data.data.managed_circle.account.gold;
                        this.totalAmount = this.gold
                    }
                })
            },
            handelConfig() {
                this.$http.get(this.$api.rechargeConfigs).then(result => {
                    if (result.data.success) {
                        this.payConfigs = result.data.data.products
                        if (this.payConfigs.length) {
                            this.choseType(0, this.payConfigs[0])
                        }
                    }
                })
            },
            // tab切换
            handleClick(tab) {
                if (tab.name === 'balance') {
                    this.$router.push('/balance');
                }
            },
            choseType(i, item) {
                this.numberCheck = false;
                this.input = '';
                this.choseIndex = i;
                this.recharge_id = item.id;
                this.payable = item.amount;
            },
            choseState(i, item) {
                this.choseIndexPay = i;
                this.type = item.id;
            },
            coins() {
                let checkNum = /^[0-9]*$/;
                if (this.input === '') {
                    this.input = 0;
                    if (this.payConfigs.length) {
                        this.choseType(0, this.payConfigs[0])
                    }
                } else if (this.input === '0' || this.input === 0) {
                    this.input = '';
                    this.$message({
                        message: '金额不能为0',
                        type: 'warning',
                    });
                } else if (parseInt(this.input) > 100000) {
                    this.input = 100000;
                    this.recharge_id = null;
                    this.payable = 100000;
                    this.choseIndex = -1;
                    this.numberCheck = true;
                } else if (checkNum.test(this.input)) {
                    this.recharge_id = null;
                    this.payable = this.input;
                    this.choseIndex = -1;
                    this.numberCheck = true;
                } else {
                    this.input = '';
                    this.choseType(0, this.payConfigs[0])
                    this.$message({
                        message: '只能输入数字且金额大于0',
                        type: 'warning',
                    });
                }
            },
            openDiog() {
                this.qr_code_loading = true;
                let data = {
                    amount: this.payable,
                    source: 'pc',
                };
                if (data.amount < 1) {
                    return
                }
                if (this.recharge_id) {
                    data.recharge_id = this.recharge_id
                }
                this.$http.post(this.$api.rechargeCreateOrder, data, true).then(result => {
                    if (result.data.success) {
                        this.order_no = result.data.data.order_no;
                        this.initPay(result.data.data.order_no, this.type)
                        this.confirmDialogVisible = true;
                    }
                })
            },
            initPay(no, type) {
                let data = {
                    order_no: no,
                    method: type === 1 ? 'alipay' : 'wechat'
                };
                this.$http.post(this.$api.rechargeScanPay, data, true).then(result => {
                    if (result.data.success && result.data.code === 200000) {
                        this.qr_code = result.data.data;
                        this.payment_status = false;
                        this.qr_code_loading = false;
                        this.MonitorPaymentStatus(no)
                    } else {
                        const h = this.$createElement;
                        this.$msgbox({
                            title: '系统提示',
                            message: h('p', null, [
                                h('span', null, result.data.message),
                            ]),
                            confirmButtonText: '确定',
                            beforeClose: (action, instance, done) => {
                                if (action === 'confirm') {
                                    this.payment_status = false;
                                    this.qr_code_loading = false;
                                    this.confirmDialogVisible = false;
                                    done()
                                } else {
                                    this.payment_status = false;
                                    this.qr_code_loading = false;
                                    this.confirmDialogVisible = false;
                                    done()
                                }
                            }
                        });
                    }
                })
            },
            MonitorPaymentStatus(no, isStop = false) {
                if (this.payment_status) {
                    return
                }
                this.$http.get(this.$api.rechargePayResult + '?f=' + this.$handle.encryption({
                    no: no,
                    type: 'recharge',
                    source: 'pc'
                }), true).then(result => {
                    if (result.data.success && result.data.code === 200000 && result.data.data?.status === 'success') {
                        this.initInfo();
                        this.order_no = null;
                        this.sucdialogVisible = true;
                        this.payment_status = true;
                        this.qr_code = '';
                        this.confirmDialogVisible = false;
                    } else if (result.data.success && result.data.code === 200202) {
                        if (!isStop) {
                            setTimeout(() => {
                                this.MonitorPaymentStatus(no)
                            }, 1000)
                        } else {
                            this.payment_status = true;
                        }

                    }

                })
            },
            toBalance() {
                this.$router.push('/balance');
            },
            recharge() {
                this.errdialogVisible = false;
            },
            closePop(done = null) {
                if (this.order_no) {
                    this.MonitorPaymentStatus(this.order_no, true);
                }
                if (done.isFunction){
                    done();
                }
                setTimeout(() => {
                    this.payment_status = false;
                    this.qr_code = '';
                    this.confirmDialogVisible = false;
                    this.input = '';
                    if (this.payConfigs.length) {
                        this.choseType(0, this.payConfigs[0])
                    }
                }, 100)
            },
        },

        components: {
            VueQr
        },
    };
</script>
<style lang="less" scoped>
    @import '~assets/less/balance/pay.less';

    /deep/ .numberSelect input {
        border-color: #81d8d0 !important;
    }
</style>
<style>
    .el-message {
        z-index: 999999 !important;
    }
</style>